import React, { useContext, useState, useRef, useEffect } from "react";
import style from "./AppDashboard.module.css";

// Components
import PromptInputContainer from "../../components/PromptInputContainer/PromptInputContainer";
import { VideoPlayer } from "../../components/VideoPlayer/VideoPlayer";
import { Helmet } from "react-helmet-async";

// Context
import { AppContext } from "../../context/appContext";
import { VideoContext } from "../../context/videoContext";

// Functions
import { generateImage } from "../../api";

// Utils
import { COMMON_WORDS } from "../../utils/commonWords";

// Hooks
import { useDebounce } from "../../hooks/useDebounce";

export const HomePage = () => {
  const { setAlertOptions, userId, setUserData } = useContext(AppContext);

  const { ideaInputText, isLoading, isVideo, setIdeaInputText } =
    useContext(VideoContext);

  const { handleSubmit } = useContext(VideoContext);
  const [dropDownOptions, setDropDownOptions] = useState({
    style: "Digital",
    mode: "Torsera",
    length: "Auto",
    voice: "Male",
  });

  const [generatedImageUrl, setGeneratedImageUrl] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [lastProcessedText, setLastProcessedText] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [interimTranscript, setInterimTranscript] = useState("");

  const notificationTimeoutRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    return () => {
      if (notificationTimeoutRef.current) {
        clearTimeout(notificationTimeoutRef.current);
      }
    };
  }, []);

  const processText = async (text) => {
    const words = text
      .trim()
      .split(/[\s.,!?]+/)
      .filter(Boolean);
    const newWords = words.filter(
      (word) => !COMMON_WORDS.has(word.toLowerCase())
    );

    if (newWords.length > 0 && text !== lastProcessedText) {
      try {
        const imageUrl = await generateImage(text, userId); // Ensure userId is defined
        if (imageUrl) {
          setGeneratedImageUrl(imageUrl);
          setLastProcessedText(text);
        }
      } catch (error) {
        console.error("Error generating image:", error);
      }
    }
  };

  const debouncedProcessText = useDebounce(processText, 300, 3);

  const handleChange = (event) => {
    const text = event.target.value;
    if (text.length <= 4500) {
      setIdeaInputText(text);
    }
    if (text.length === 4500) {
      setShowNotification(true);
      if (notificationTimeoutRef.current) {
        clearTimeout(notificationTimeoutRef.current);
      }
      notificationTimeoutRef.current = setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }

    if (text.length === 0) {
      setGeneratedImageUrl("");
      setLastProcessedText("");
      return;
    }

    debouncedProcessText(text);
  };

  const handlePresetPromptClick = async (promptText) => {
    setIdeaInputText(promptText);

    try {
      const imageUrl = await generateImage(promptText, userId);
      if (imageUrl) {
        setGeneratedImageUrl(imageUrl);
      }
    } catch (error) {
      console.error("Error generating image:", error);
    } finally {
    }
  };

  return (
    <>
      <Helmet>
        <title>AI Text to Video - Limitless Narrative-Driven Videos</title>
        <meta
          name="description"
          content="Create narrative-driven videos using AI"
        />
      </Helmet>
      <div className={style["home-page"]}>
        {!isVideo ? (
          <div style={{ maxWidth: "100vw" }}>
            <div className={style["preview-input-container"]}>
              <PromptInputContainer
                setIdeaInputText={setIdeaInputText}
                interimTranscript={interimTranscript}
                setInterimTranscript={setInterimTranscript}
                isListening={isListening}
                setIsListening={setIsListening}
                onPresetPromptClick={handlePresetPromptClick}
                generatedImageUrl={generatedImageUrl}
                isLoading={isLoading}
                handleChange={handleChange}
                ideaInputText={ideaInputText}
                showNotification={showNotification}
                inputRef={inputRef}
                dropDownOptions={dropDownOptions}
                setDropDownOptions={setDropDownOptions}
                setAlertOptions={setAlertOptions}
                setUserData={setUserData}
                handleSubmit={handleSubmit}
              />
            </div>
            <div className={style["disclaimer"]}></div>
          </div>
        ) : (
          <VideoPlayer
            dropDownOptions={dropDownOptions}
            setIdeaInputText={setIdeaInputText}
          />
        )}
      </div>
    </>
  );
};
