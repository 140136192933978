import React, { useContext, useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import styles from "./time-machine.module.css";
import Lottie from "react-lottie";
import animationData from "../../images/purple-globe-minimal.json";
import TimeAdjustment from "./TimeAdjustment/TimeAdjustment";
import jamaicaRegions from "../../data/jamaica-regions.geojson";
import combinedOutputImage from "../../images/combined_output.png";
import { INITIAL_COUNTRIES } from "./initialCountries";
import TmAssistant from "./tmAssistant/tmAssistant";
import TryMeTag from "./TryMeTag/TryMeTag";
import TmExamples from "./tmExamples/tmExamples";
import { moveGlobeToCountry } from "./globeMovement";
import GlobeSpinner from "./GlobeSpinner/GlobeSpinner";
import diceIcon from "../../images/dice.svg";
import { Helmet } from "react-helmet-async";

import { AppContext } from "../../context/appContext";
import { VideoContext } from "../../context/videoContext";

import { GenerativeSearchIdeas } from "../GenerativeSearch/GenerativeSearchComponents/GenerativeSearchCards/GenerativeSearchCards";
import { VideoPlayer } from "../../components/VideoPlayer/VideoPlayer";
import { handleGenerateClick } from "../GenerativeSearch/generative-search-util-function";
import "@flaticon/flaticon-uicons/css/all/all.css";
import CountryDisplay from "./CountryDisplay/CountryDisplay";
import TimeMachineControls from "./ControlsContainer/TimeMachineControls";
import "./AnimatedSearchButton.css";
import countryFoundingDates from "../../data/countryFoundingDates.json";
import countries from "./tmAssistant/countries.json";

// Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

// MOVE THIS TO ENV VARIABLE
mapboxgl.accessToken =
  "pk.eyJ1IjoiY29iYWx0MzM3IiwiYSI6ImNseXAxajVvazBvNDMycXB2NHJ1czNjeG8ifQ.JBFsTCdGQwdL6Kop6suneg";

const getRandomPastelColor = () => {
  const hue = Math.floor(Math.random() * 360);
  const saturation = 25 + Math.floor(Math.random() * 25);
  const lightness = 85 + Math.floor(Math.random() * 10);
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

export const TimeMachine = () => {
  const { setAlertOptions, setUserData } = useContext(AppContext);
  const { handleSubmit, isVideo, setIdeaInputText } = useContext(VideoContext);
  const analytics = getAnalytics();

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [mapOpacity, setMapOpacity] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [animationComplete, setAnimationComplete] = useState(false);
  const lottieRef = useRef();
  const [shouldLoadMap, setShouldLoadMap] = useState(false);
  const [fadeTransition, setFadeTransition] = useState(false);
  const currentMarker = useRef(null);
  const [initialCountry, setInitialCountry] = useState(null);

  // New state variables to control UI elements visibility
  const [showControls, setShowControls] = useState(false);
  const [showAssistant, setShowAssistant] = useState(false);
  const [showTryMeTag, setShowTryMeTag] = useState(false);
  const [showDictationButton, setShowDictationButton] = useState(false);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    const randomDelay = Math.floor(Math.random() * (750 - 625 + 1)) + 625;
    const timer = setTimeout(() => {
      setShouldLoadMap(true);
    }, randomDelay);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const randomInitialCountry =
      INITIAL_COUNTRIES[Math.floor(Math.random() * INITIAL_COUNTRIES.length)];
    setInitialCountry(randomInitialCountry);
  }, []);

  useEffect(() => {
    if (isVideo) {
      map.current = null;
      mapContainer.current = null;
    }
    if (map.current || !shouldLoadMap || !initialCountry || isVideo) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: initialCountry.coords,
      zoom: 2.05,
      pitch: 22,
      bearing: 0,
    });

    map.current.on("load", () => {
      // Set initial fog for space effect
      map.current.setFog({
        color: "rgb(150, 180, 210)",
        "high-color": "rgb(30, 70, 180)",
        "horizon-blend": 0.015,
        "space-color": "rgb(15, 15, 35)",
        "star-intensity": 0.4,
      });

      // Perform dramatic camera animation
      map.current.easeTo({
        center: initialCountry.coords,
        zoom: window.innerWidth <= 768 ? 1.3 : 2.3,
        pitch: 0,
        bearing: 0,
        duration: 2600,
        easing: (t) => {
          return 1 - Math.pow(1 - t, 3);
        },
      });

      // Hide major road layers
      [
        "road-motorway-trunk",
        "road-primary",
        "road-secondary-tertiary",
        "road-major-link",
      ].forEach((layer) => {
        map.current.setLayoutProperty(layer, "visibility", "none");
      });

      // Remove road-related layers
      const layers = map.current.getStyle().layers;
      for (let i = 0; i < layers.length; i++) {
        const layer = layers[i];
        if (
          layer.id.includes("road") ||
          layer.id.includes("highway") ||
          layer.id.includes("street") ||
          layer.id.includes("bridge") ||
          layer.id.includes("tunnel")
        ) {
          map.current.removeLayer(layer.id);
        }
      }

      // Add brightness adjustment layer
      map.current.addLayer(
        {
          id: "brightness-adjustment",
          type: "background",
          paint: {
            "background-color": "rgba(0, 0, 0, 0.005)",
          },
        },
        "country-label"
      );

      // Fetch country data and add it to the map
      fetch(
        "https://d2ad6b4ur7yvpq.cloudfront.net/naturalearth-3.3.0/ne_50m_admin_0_countries.geojson"
      )
        .then((response) => response.json())
        .then((data) => {
          map.current.addSource("countries", {
            type: "geojson",
            data: data,
          });

          // Add the country fill layer
          map.current.addLayer(
            {
              id: "country-tints",
              type: "fill",
              source: "countries",
              paint: {
                "fill-color": [
                  "case",
                  ["has", "color"],
                  ["get", "color"],
                  getRandomPastelColor(),
                ],
                "fill-opacity": 0.5,
              },
              interactive: true,
            },
            "country-label"
          );

          // Modify the country borders layer
          map.current.addLayer(
            {
              id: "country-borders",
              type: "line",
              source: "countries",
              layout: {},
              paint: {
                "line-color": "#000000",
                "line-width": 1,
                "line-opacity": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  2,
                  0.05, // At zoom level 2 and below, opacity is 0.05
                  6,
                  0.15, // At zoom level 6 and above, opacity is 0.15
                ],
              },
            },
            "country-label"
          );

          data.features.forEach((feature) => {
            feature.properties.color = getRandomPastelColor();
          });

          map.current.getSource("countries").setData(data);
        });

      // Add Jamaica regions source and layer
      map.current.addSource("jamaica-regions", {
        type: "geojson",
        data: jamaicaRegions,
      });

      map.current.addLayer(
        {
          id: "jamaica-regions",
          type: "fill",
          source: "jamaica-regions",
          paint: {
            "fill-color": [
              "match",
              ["get", "name"],
              "Plantation Heartland",
              "#ff9999",
              "Maroon Settlements",
              "#99ff99",
              "Kingston Growth",
              "#9999ff",
              "#888888", // default color
            ],
            "fill-opacity": [
              "interpolate",
              ["linear"],
              ["zoom"],
              5,
              0, // Fully transparent at zoom level 5 and below
              7,
              0.7, // 70% opaque at zoom level 7 and above
            ],
          },
        },
        "country-label"
      );

      map.current.addLayer(
        {
          id: "jamaica-regions-outline",
          type: "line",
          source: "jamaica-regions",
          paint: {
            "line-color": "#000000",
            "line-width": 1,
            "line-opacity": ["interpolate", ["linear"], ["zoom"], 5, 0, 7, 1],
          },
        },
        "country-label"
      );

      // Add hover effect
      map.current.on("mousemove", "jamaica-regions", (e) => {
        if (e.features.length > 0) {
          map.current.getCanvas().style.cursor = "pointer";
          // You can add a popup or other visual feedback here
        }
      });

      map.current.on("mouseleave", "jamaica-regions", () => {
        map.current.getCanvas().style.cursor = "";
        // Remove popup or other visual feedback here
      });

      // Add click event
      map.current.on("click", "jamaica-regions", (e) => {
        if (e.features.length > 0) {
          const regionName = e.features[0].properties.name;
          // could potentially open modals w/ region info eventually(??)
        }
      });

      // Custom cursor behavior
      map.current.getCanvas().classList.add(styles.customCursor);

      map.current.on("mousedown", () => {
        map.current.getCanvas().classList.remove(styles.customCursor);
        map.current.getCanvas().classList.add(styles.grabbingCursor);
      });

      map.current.on("mouseup", () => {
        map.current.getCanvas().classList.remove(styles.grabbingCursor);
        map.current.getCanvas().classList.add(styles.customCursor);
      });

      // Trigger the fade transition for the map and dictation button
      setFadeTransition(true);
      setShowDictationButton(true);
      setIsLoading(false);

      // Delay showing other UI elements
      setTimeout(() => {
        setShowAssistant(true);
        setShowTryMeTag(true);
      }, 500); // Adjust this delay as needed

      // Add zoom event listener
      map.current.on("zoom", () => {
        const zoomLevel = map.current.getZoom();
        const isMobile = window.innerWidth <= 768;

        if (zoomLevel > 4.5) {
          map.current.setZoom(4.5);
        }

        // Existing navbar zoom event
        const zoomedIn = zoomLevel >= 3.03;
        const navbarEvent = new CustomEvent("zoomLevelChange", {
          detail: { zoomedIn },
        });
        window.dispatchEvent(navbarEvent);

        // New UI styling event with mobile threshold
        const useAdaptiveStyle = isMobile
          ? zoomLevel >= 1.25
          : zoomLevel >= 2.44;
        const uiEvent = new CustomEvent("uiStyleChange", {
          detail: { useAdaptiveStyle },
        });
        window.dispatchEvent(uiEvent);
      });

      // Modify the existing click event handler
      map.current.on("click", (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: ["country-tints"],
        });

        if (features.length === 0) {
          console.log("Clicked on ocean - no action taken");
          return;
        }

        const { lng, lat } = e.lngLat;
        const countryName =
          features[0].properties.name ||
          features[0].properties.NAME ||
          features[0].properties.country;

        if (countryName) {
          setSearchInput((prev) => ({
            ...prev,
            latitude: lat,
            longitude: lng,
            country: countryName,
          }));

          // Update marker
          if (currentMarker.current) {
            currentMarker.current.remove();
          }
          currentMarker.current = new mapboxgl.Marker({ color: "red" })
            .setLngLat([lng, lat])
            .addTo(map.current);

          // Update country selection
          handleCountrySelection({
            country: countryName,
            isManualClick: true,
          });
        }

        setShowControls(true);
      });

      // Remove city labels and related POIs
      const layersToRemove = [
        "settlement-major-label",
        "settlement-minor-label",
        "settlement-subdivision-label",
        "airport-label",
        "poi-label",
        "water-point-label",
        "water-line-label",
        "natural-point-label",
        "natural-line-label",
        "place-label",
      ];

      layersToRemove.forEach((layer) => {
        if (map.current.getLayer(layer)) {
          map.current.removeLayer(layer);
        }
      });

      // Add this new code to adjust internal boundaries
      const adminBoundaryLayers = ["admin-1-boundary", "admin-1-boundary-bg"];

      adminBoundaryLayers.forEach((layer) => {
        if (map.current.getLayer(layer)) {
          const currentOpacity = map.current.getPaintProperty(
            layer,
            "line-opacity"
          );
          const newOpacity =
            typeof currentOpacity === "number" ? currentOpacity * 0.55 : 0.55;
          map.current.setPaintProperty(layer, "line-opacity", newOpacity);
        }
      });
    });
  }, [shouldLoadMap, initialCountry, isVideo]);

  const handleAnimationComplete = () => {
    setAnimationComplete(true);
  };

  const [selectedCountry, setSelectedCountry] = useState(null);

  const [showSpinner, setShowSpinner] = useState(false);

  const handleCountrySelection = ({ country, date, isManualClick = false }) => {
    logEvent(analytics, `button_click_time_machine_country_selection`, {
      country: country,
      date: date,
    });
    if (map.current) {
      if (isManualClick) {
        setSelectedCountry(country);
        setSearchInput((prev) => ({
          ...prev,
          country: country,
          year: date ? parseInt(date, 10) : prev.year,
        }));
      } else {
        setShowSpinner(true);
        moveGlobeToCountry(
          map.current,
          country,
          currentMarker,
          (clickedCountry) => {
            setSelectedCountry(clickedCountry);
            setSearchInput((prev) => ({
              ...prev,
              country: clickedCountry,
              year: date ? parseInt(date, 10) : prev.year,
            }));
          },
          () => setShowSpinner(true),
          () => setShowSpinner(false) // Add onMoveComplete callback
        );
      }
    }
  };

  const handleRandomSelection = () => {
    logEvent(analytics, `button_click_time_machine_random_selection`);
    const randomCountry =
      countries[Math.floor(Math.random() * countries.length)];
    const minYear = -500;
    const maxYear = 2024;
    let randomYear = Math.floor(
      Math.random() * (maxYear - minYear + 1) + minYear
    );
    randomYear = Math.round(randomYear / 25) * 25;

    handleCountrySelection({
      country: randomCountry,
      date: randomYear.toString(),
    });
  };

  const [ideas, setIdeas] = useState(Array(6).fill({ ready: false }));
  const [showCards, setShowCards] = useState(false);
  const [searchInput, setSearchInput] = useState({
    latitude: 0,
    longitude: 0,
    country: "",
    region: "",
    year: 2024,
    yearRange: "2024", // New field added
  });
  const [loadedImages, setLoadedImages] = useState({});
  const [isCardsLoading, setIsCardsLoading] = useState(false);

  const [overlayVisible, setOverlayVisible] = useState(false);
  const [overlayPreloaded, setOverlayPreloaded] = useState(false);
  const [cardsVisible, setCardsVisible] = useState(false);

  useEffect(() => {
    // Preload the overlay after a short delay
    const timer = setTimeout(() => {
      setOverlayPreloaded(true);
    }, 1000); // Adjust this delay as needed

    return () => clearTimeout(timer);
  }, []);

  function handleGenerate() {
    logEvent(analytics, `button_click_time_machine_generate`, {
      country: searchInput.country,
      year: searchInput.yearRange,
    });
    // Get the first year from the range
    const firstYear = parseInt(searchInput.yearRange.split("-")[0]);

    // Check if the country existed in the selected year
    const foundingYear = countryFoundingDates[searchInput.country];
    const countryTerminology =
      foundingYear && firstYear < foundingYear
        ? `${searchInput.country} region`
        : searchInput.country;

    // Add AD suffix for years between 1-1000
    const yearRangeWithAD =
      firstYear > 1 && firstYear < 1000
        ? `${searchInput.yearRange} AD`
        : searchInput.yearRange;

    // New helper function for century descriptions
    const getCenturyDescriptor = (year) => {
      if (year <= 0) {
        const absYear = Math.abs(year);
        const century = Math.ceil(absYear / 100);
        const yearInCentury = absYear % 100;
        const quarter =
          yearInCentury <= 24
            ? "first"
            : yearInCentury <= 49
              ? "second"
              : yearInCentury <= 74
                ? "third"
                : "fourth";
        return `(${quarter} quarter of the ${century}th century BCE)`;
      } else {
        const century = Math.ceil(year / 100);
        const yearInCentury = year % 100;
        const quarter =
          yearInCentury <= 24
            ? "first"
            : yearInCentury <= 49
              ? "second"
              : yearInCentury <= 74
                ? "third"
                : "fourth";
        const centuryStr =
          century + (["", "st", "nd", "rd"][century % 10] || "th");
        return `(${quarter} quarter of the ${centuryStr} century)`;
      }
    };

    // Add century descriptor to search term
    const centuryDescriptor = getCenturyDescriptor(firstYear);

    const searchTerm = `We need some compelling videos that are specific to ${yearRangeWithAD} ${countryTerminology} ${centuryDescriptor}. Shoot for mini-doc style concepts [relating to culture/events/strange things that happened etc]. Be sure to include some that are a bit out of left-field/unique. Also, we are looking for very interesting concepts that the History Channel might cover if they had this task we are assigning you.`;

    setOverlayVisible(true);
    setShowControls(false);
    setShowDictationButton(false);
    setShowCards(true);
    setTimeout(() => setCardsVisible(true), 50);
    handleGenerateClick(
      searchTerm,
      isCardsLoading,
      setIsCardsLoading,
      setShowCards,
      setIdeas,
      ideas
    );
  }

  function handleCloseCards() {
    setCardsVisible(false);
    setOverlayVisible(false);
    setTimeout(() => {
      setShowCards(false);
      setShowControls(true);
      setShowDictationButton(true);
    }, 350);
  }

  const handleCardGenerate = (title, description) => {
    logEvent(analytics, `button_click_time_machine_generate_idea`, {
      title: title,
      description: description,
    });
    handleSubmit(
      "time machine",
      `"${title}" ${description}`,
      {
        style: "Digital",
        mode: "Torsera",
        length: "Auto",
        voice: "Male",
        addonReplaceMode: "Digital",
      },
      setAlertOptions,
      setIdeaInputText,
      setUserData
    );
  };

  useEffect(() => {
    if (currentMarker.current) {
      if (showCards) {
        currentMarker.current.getElement().style.display = "none";
      } else {
        currentMarker.current.getElement().style.display = "block";
      }
    }
  }, [showCards]);

  return (
    <>
      <Helmet>
        <title>
          Torsera Time Machine - Create AI Narrarated Videos About Any Time and
          Place
        </title>
        <meta
          name="description"
          content="Create AI narrated videos about any time and place"
        />
      </Helmet>
      <div className={styles.timeMachineContainer}>
        {!isVideo ? (
          <>
            <div
              className={`${styles.loadingContainer} ${fadeTransition ? styles.fadeOut : ""}`}
            >
              <Lottie
                options={defaultOptions}
                height={400}
                width={400}
                eventListeners={[
                  {
                    eventName: "complete",
                    callback: handleAnimationComplete,
                  },
                ]}
                ref={lottieRef}
              />
            </div>
            <div
              ref={mapContainer}
              className={`${styles.mapContainer} ${fadeTransition ? styles.fadeIn : ""}`}
            />
            {/* Separate TmAssistant from other UI elements */}
            <div
              className={`${styles.dictationButtonContainer} ${showDictationButton ? styles.visible : styles.hidden}`}
            >
              <TmAssistant
                show={showDictationButton}
                onCountrySelect={handleCountrySelection}
              />
              {showDictationButton && <TmExamples />}
              <button
                className={styles.randomButton}
                onClick={handleRandomSelection}
                aria-label="Random Selection"
              >
                <img src={diceIcon} alt="Dice" className={styles.diceIcon} />
              </button>
            </div>
            <div
              className={`${styles.timeMachineControls} ${
                showControls ? styles.visible : styles.hidden
              } ${styles.raisedControls}`}
            >
              <TimeMachineControls
                countryName={selectedCountry}
                setSearchInput={setSearchInput}
                visible={showControls}
                selectedYear={searchInput.year}
                searchInput={searchInput}
              />
              <button className="uiverse" onClick={handleGenerate}>
                <div className="wrapper">
                  <span>
                    <i className="fi fi-rr-sparkles"></i> Explore
                  </span>
                  <div className="circle circle-12"></div>
                  <div className="circle circle-11"></div>
                  <div className="circle circle-10"></div>
                  <div className="circle circle-9"></div>
                  <div className="circle circle-8"></div>
                  <div className="circle circle-7"></div>
                  <div className="circle circle-6"></div>
                  <div className="circle circle-5"></div>
                  <div className="circle circle-4"></div>
                  <div className="circle circle-3"></div>
                  <div className="circle circle-2"></div>
                  <div className="circle circle-1"></div>
                </div>
              </button>
            </div>
            <div
              className={`
              ${styles.overlay} 
              ${overlayPreloaded ? styles.preloaded : ""}
              ${overlayVisible ? styles.visible : ""}
            `}
            />

            {showCards && (
              <div
                className={`${styles.cardsContainer} ${cardsVisible ? styles.visible : ""}`}
              >
                <div
                  className={`${styles.cardsBackgroundContainer} ${cardsVisible ? styles.visible : ""}`}
                >
                  <button
                    className={`${styles.closeButton} ${cardsVisible ? styles.visible : ""}`}
                    onClick={handleCloseCards}
                  >
                    &times;
                  </button>
                  <GenerativeSearchIdeas
                    ideas={ideas.map((idea) => ({
                      ...idea,
                      ready: idea.imageUrl !== undefined,
                    }))}
                    loadedImages={loadedImages}
                    setLoadedImages={setLoadedImages}
                    isLoading={isCardsLoading}
                    onGenerate={handleCardGenerate}
                    theme="timeMachine"
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <div className={styles.videoContainer}>
            <VideoPlayer
              dropDownOptions={{
                style: "Digital",
                mode: "Torsera",
                length: "Auto",
                voice: "Male",
              }}
              setIdeaInputText={setIdeaInputText}
            />
          </div>
        )}
        <GlobeSpinner
          isVisible={showSpinner}
          onAnimationComplete={() => setShowSpinner(false)}
        />
      </div>
    </>
  );
};

export default TimeMachine;
