export const INITIAL_COUNTRIES = [
  { name: 'Paraguay', coords: [-58, -23] },
  { name: 'Mongolia', coords: [105, 46] },
  { name: 'Laos', coords: [102, 18] },
  { name: 'Jamaica', coords: [-77.3, 18.1] },
  { name: 'France', coords: [2, 46] },
  { name: 'Germany', coords: [10, 51] },
  { name: 'Iran', coords: [53, 32] },
  { name: 'Mali', coords: [-4, 17] },
  { name: 'Uganda', coords: [32, 1] },
  { name: 'Japan', coords: [138, 36] },
  { name: 'Brazil', coords: [-53, -14] },
  { name: 'Egypt', coords: [30, 27] },
  { name: 'Australia', coords: [133, -25] },
  { name: 'India', coords: [77, 20] },
  { name: 'Mexico', coords: [-102, 23] },
  { name: 'Spain', coords: [-4, 40] },
  { name: 'Italy', coords: [12, 42] },
  { name: 'Poland', coords: [20, 52] },
  { name: 'Sweden', coords: [18, 62] },
  { name: 'Greece', coords: [22, 39] },
  { name: 'Saudi Arabia', coords: [45, 25] },
  { name: 'Vietnam', coords: [108, 16] },
  { name: 'Suriname', coords: [-56, 4] },
  { name: 'Peru', coords: [-76, -10] },
  { name: 'Kenya', coords: [38, 1] },
  { name: 'Zambia', coords: [28, -15] },
  { name: 'Canada', coords: [-106, 56] },
  { name: 'Argentina', coords: [-64, -34] },
  { name: 'Thailand', coords: [101, 15] },
  { name: 'South Korea', coords: [128, 36] },
  { name: 'Indonesia', coords: [120, -5] },
  { name: 'Turkey', coords: [35, 39] },
  { name: 'United Kingdom', coords: [-2, 54] },
  { name: 'Ukraine', coords: [31, 49] },
  { name: 'Colombia', coords: [-72, 4] },
  { name: 'Philippines', coords: [122, 13] }
];