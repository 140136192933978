import React, { useEffect, useRef } from "react";
import { ImageIcon, SpeakerLoudIcon, PlayIcon } from "@radix-ui/react-icons";
import { motion } from "framer-motion";
import './ExploreButton.css';
import Globe from './Globe';

const MainContent = ({ handleLoginClick }) => {

  return (
    <main className="container mx-auto px-4 sm:px-6 lg:px-8 pt-4 sm:pt-6 lg:pt-8 pb-12 sm:pb-16 lg:pb-20">
      <h1 className="mb-6 sm:mb-8 text-center text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-light tracking-tight text-white">
        Time Travel With Gen AI
      </h1>

      <motion.div
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, delay: 0.3, ease: "easeOut" }}
        className="mx-auto mb-8 sm:mb-8 w-[calc(100%-24px)] sm:w-[calc(80%-24px)] md:w-[calc(75%-24px)] lg:w-[calc(66.666%-24px)] xl:w-[calc(50%-76px)] text-center flex flex-col sm:flex-row justify-center items-center flex-wrap py-2 sm:py-3 px-1 sm:px-2 border border-gray-500 rounded-full shadow-sm"
        style={{
          borderWidth: "0.5px",
          borderColor: "rgba(107, 114, 128, 0.27)",
        }}
      >
        <span className="mb-2 sm:mb-0 mr-1 font-medium bg-gradient-to-r from-[rgb(160,80,234)] via-[rgb(182,125,239)] to-[rgb(160,80,234)] bg-clip-text text-transparent text-[0.9375rem] sm:text-[1.0625rem] md:text-[1.1875rem]">
          We use:
        </span>

        <div className="flex flex-row flex-wrap justify-center items-center">
          {[
            { icon: SpeakerLoudIcon, text: "Voice" },
            { icon: ImageIcon, text: "AI-Images" },
            { icon: PlayIcon, text: "Music" },
          ].map((item, index) => (
            <React.Fragment key={item.text}>
              <motion.span
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  duration: 0.8,
                  delay: 0.2 + index * 0.2,
                  ease: "easeOut",
                }}
                className="inline-flex items-center mx-1 my-1 px-[0.5625rem] py-[0.3125rem] sm:px-[0.6875rem] sm:py-[0.40625rem] rounded-full bg-gray-800 bg-opacity-30"
              >
                <item.icon className="w-4 h-4 sm:w-[1.125rem] sm:h-[1.125rem] mr-1.5 text-gray-300" />
                <span className="text-[0.8125rem] sm:text-[0.875rem] text-gray-300">
                  {item.text}
                </span>
              </motion.span>
              {index < 2 && (
                <span className="mx-1 text-gray-500 text-sm">•</span>
              )}
            </React.Fragment>
          ))}
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 1.0, ease: "easeOut" }}
        className="text-center"
      >
        <button className="button" onClick={handleLoginClick}>
          <span className="button-border"></span>
          Explore Now
        </button>
      </motion.div>

      {/* Hidden preload globe */}
      <div className="hidden">
        <Globe />
      </div>

      {/* Visible globe */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 1.2, ease: "easeOut" }}
        className="w-full max-w-2xl mx-auto h-[600px] -mt-20 sm:-mt-5 overflow-visible"
      >
        <Globe />
      </motion.div>

    </main>
  );
};

export default MainContent;
