import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Navbar.module.css";
import "@flaticon/flaticon-uicons/css/all/all.css";

// Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

// Components
import BorderGlowButton from "./PricingButton.tsx";
import { CrystalBall } from "../../icons/CrystalBall";

// Context
import { AppContext } from "../../context/appContext";
import { VideoContext } from "../../context/videoContext";

// Router
import { Link, useNavigate, useLocation } from "react-router-dom";

export const Navbar = () => {
  const {
    isAuthenticated,
    setIsLoginModalOpen,
    setIsPricingModalOpen,
    userData,
  } = useContext(AppContext);

  const { setIsVideo } = useContext(VideoContext);

  const analytics = getAnalytics();
  const navigate = useNavigate();
  const location = useLocation();

  const isSearchFeature = location.pathname.includes("/generative-search");
  const mobileMenuRef = useRef(null);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isZoomedIn, setIsZoomedIn] = useState(false);

  // Listen to zoom level changes
  useEffect(() => {
    const handleZoomLevelChange = (e) => {
      setIsZoomedIn(e.detail.zoomedIn);
    };

    window.addEventListener("zoomLevelChange", handleZoomLevelChange);

    return () => {
      window.removeEventListener("zoomLevelChange", handleZoomLevelChange);
    };
  }, []);

  // Reset isZoomedIn when route changes
  useEffect(() => {
    setIsZoomedIn(false);
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target)
      ) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMobileOpen(!isMobileOpen);
    document.body.style.overflow = isMobileOpen ? "auto" : "hidden";
  };

  const closeMenu = () => {
    setIsMobileOpen(false);
    document.body.style.overflow = "auto";
  };

  const handleNavigation = (path) => {
    navigate(path);
    toggleMenu();
    logEvent(analytics, `navigation_${path.replace("/", "")}`);
  };

  return (
    <header>
      <div className={`${styles.topBar} ${isAuthenticated ? "" : styles.dark}`}>
        {isAuthenticated ? (
          <nav className={styles.topBarNav}>
            <div
              className={`
              ${styles.glassContainer}
              ${isSearchFeature ? styles.glassContainerSearch : ""}
              ${isZoomedIn ? styles.zoomedInGlassContainer : ""}
            `}
            >
              <div className={styles.creditsDisplay}>
                <span className={styles.creditsLabel}>Credits:</span>
                <i className="fi fi-br-coins"></i>
                <span className={styles.creditsNumber}>
                  {userData?.credits || 0}
                </span>
              </div>
              <div className={styles.pricingButtonWrapper}>
                <BorderGlowButton
                  onClick={() => {
                    setIsPricingModalOpen(true);
                    logEvent(
                      analytics,
                      `button_click_open_pricing_modal_navbar`
                    );
                  }}
                />
              </div>
              <button
                className={styles.headerButton}
                id="userInitial"
                onClick={() => {
                  navigate("/account");
                  logEvent(analytics, `button_click_account`);
                }}
              >
                {userData.initial}
              </button>
              <div className={styles.hamburger} onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div
              ref={mobileMenuRef}
              className={`${styles.mobileMenu} ${isMobileOpen ? styles.open : ""}`}
            >
              <button className={styles.closeButton} onClick={closeMenu}>
                X
              </button>
              <Link
                to="/"
                onClick={() => {
                  handleNavigation("/direct-mode");
                  setIsVideo(false);
                }}
              >
                Direct Mode
              </Link>
              <Link
                to="/time-machine"
                onClick={() => {
                  handleNavigation("/time-machine");
                  setIsVideo(false);
                }}
              >
                Time Machine
              </Link>
              <Link
                to="/generative-search"
                onClick={() => {
                  handleNavigation("/generative-search");
                  setIsVideo(false);
                }}
              >
                Generative Search
              </Link>
              <Link
                to="/account"
                onClick={() => {
                  handleNavigation("/account");
                  setIsVideo(false);
                }}
              >
                Account
              </Link>
              <button
                onClick={() => {
                  toggleMenu();
                  setIsPricingModalOpen(true);
                }}
              >
                Pricing
              </button>
              {/* <button onClick={() => {toggleMenu()}} >
                Logout
              </button> */}
            </div>
            {isMobileOpen && (
              <div className={styles.overlay} onClick={closeMenu}></div>
            )}
          </nav>
        ) : (
          <nav className={styles.topBarNav}>
            <div
              className={styles.loginButton}
              onClick={() => setIsLoginModalOpen(true)}
            >
              Login
            </div>
          </nav>
        )}
      </div>
    </header>
  );
};
