import axios from "axios";
import {
  informationalWords,
  fictionalWords,
  unexpectedWords,
} from "../../constants/constants";

// Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

export const generateSearchIdeas = async (searchInput) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/generate_search_ideas`,
      { userInput: searchInput },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    const processedIdeas = await axios.post(
      `${process.env.REACT_APP_API_URL}/process_ideas`,
      { ideas: response.data },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    return processedIdeas.data.map((idea) => ({ ...idea, ready: true }));
  } catch (error) {
    console.error("Error generating ideas:", error);
    throw error;
  }
};

export function getRandomInitialSuggestions(theme = "") {
  const getRandomItem = (arr) => arr[Math.floor(Math.random() * arr.length)];
  const allWords = [
    ...informationalWords,
    ...fictionalWords,
    ...unexpectedWords,
  ];

  if (theme) {
    const relatedWords = allWords.filter(
      (word) =>
        word.toLowerCase().includes(theme.toLowerCase()) ||
        theme.toLowerCase().includes(word.toLowerCase())
    );
    return relatedWords.length >= 4
      ? relatedWords.slice(0, 4)
      : [...relatedWords, ...getRandomInitialSuggestions()].slice(0, 4);
  }

  return [
    getRandomItem(informationalWords),
    getRandomItem(fictionalWords),
    getRandomItem(unexpectedWords),
    getRandomItem(allWords),
  ];
}

export async function handleGenerateClick(
  searchInput,
  isLoading,
  setIsLoading,
  setShowCards,
  setIdeas,
  ideas
) {
  const analytics = getAnalytics();

  if (searchInput.trim() !== "" && !isLoading) {
    logEvent(analytics, `button_click_generate_search_ideas`, {
      userInput: searchInput,
    });
    setIsLoading(true);
    setShowCards(true);
    setIdeas(ideas.map(() => ({ ready: false })));

    try {
      const generatedIdeas = await generateSearchIdeas(searchInput);
      setIdeas(generatedIdeas.map((idea) => ({ ...idea, ready: true })));
    } catch (error) {
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false);
    }
  }
}
