import React, { useContext, useEffect, useState, useRef } from "react";
import style from "./VideoPlayer.module.css";
import NeuralLoader from "../NeuralLoader/NeuralLoader";

// Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

// Context
import { AppContext } from "../../context/appContext";
import { VideoContext } from "../../context/videoContext";

// Icons
import { ChevronLeft } from "../../icons/ChevronLeft";

// Watermark image
import watermarkImage from "../../images/sitenameplusicon.svg";

export const VideoPlayer = ({ dropDownOptions, setIdeaInputText }) => {
  const { setAlertOptions, setUserData } = useContext(AppContext);

  const {
    handleSubmit,
    isLoading,
    nextVideoSuggestions,
    setIsVideo,
    setVideoOptions,
    videoOptions,
  } = useContext(VideoContext);

  const analytics = getAnalytics();

  const [isFirstPlay, setIsFirstPlay] = useState(true);
  const [isPaused, setIsPaused] = useState(true);
  const [isVideoFinished, setIsVideoFinished] = useState(false);
  const [pausedTime, setPausedTime] = useState(() => {
    const savedTime = localStorage.getItem("pausedTime");
    return savedTime ? parseFloat(savedTime) : 0;
  });
  const [loaded, setLoaded] = useState(false);
  const [storyPrompt, setStoryPrompt] = useState("");
  const imageContainerRef = useRef(null);
  const animationRef = useRef(null);
  const subtitleRef = useRef(null);
  const voiceoverRef = useRef(null);
  const backgroundMusicRef = useRef(null);
  const [isVideoContentVisible, setIsVideoContentVisible] = useState(false);

  useEffect(() => {
    if (pausedTime !== null) {
      localStorage.setItem("pausedTime", pausedTime?.toString());
    } else {
      localStorage.removeItem("pausedTime");
    }
  }, [pausedTime]);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(isLoading);
    }, 100);

    return () => clearTimeout();
  }, [isLoading]);

  useEffect(() => {
    if (voiceoverRef.current && videoOptions.voiceoverUrl) {
      voiceoverRef.current.src = videoOptions.voiceoverUrl;
      voiceoverRef.current.load();
    }

    const elapsed = videoOptions.elapsedTime;

    const duration =
      videoOptions.segmentDurations[videoOptions.currentIndex || 0] * 1000;

    const progress = Math.min(elapsed / duration, 1);

    const currentImage = imageContainerRef.current.querySelector(
      `.${style["panning-image"]}.${style["active"]}`
    );

    if (currentImage) {
      const translateX = -19.5 * progress;
      // const panTime = duration / 1000;
      currentImage.style.transform = `translateX(${translateX}%)`;
      // currentImage.style.animation = `pan ${panTime}s linear infinite`;
    }
  }, [videoOptions.voiceoverUrl]);

  useEffect(() => {
    if (backgroundMusicRef.current && videoOptions.musicFileUrl) {
      backgroundMusicRef.current.src = videoOptions.musicFileUrl;
      backgroundMusicRef.current.load();
      backgroundMusicRef.current.volume = 0.85;
    }
  }, [videoOptions.musicFileUrl]);

  useEffect(() => {
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      if (subtitleRef.current) {
        cancelAnimationFrame(subtitleRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (videoOptions.isPlaying) {
      animationRef.current = requestAnimationFrame(animateImage);
      subtitleRef.current = requestAnimationFrame(updateSubtitle);
    }
  }, [videoOptions.isPlaying, videoOptions.currentIndex]);

  useEffect(() => {
    return () => {
      pauseSlideshow();
    };
  }, []);

  useEffect(() => {
    if (voiceoverRef.current && backgroundMusicRef.current) {
      // const handlePlay = () => {
      //   backgroundMusicRef.current.play();
      // };
      // const handlePause = () => {
      //   backgroundMusicRef.current.pause();
      // };
      // const handleEnded = () => {
      //   backgroundMusicRef.current.pause();
      // };
      // voiceoverRef.current?.addEventListener("play", handlePlay);
      // voiceoverRef.current?.addEventListener("pause", handlePause);
      // voiceoverRef.current?.addEventListener("ended", handleEnded);
      // backgroundMusicRef.current.loop = true;
      // backgroundMusicRef.current.volume = 0.3;
      // return () => {
      //   if (voiceoverRef.current) {
      //     voiceoverRef.current.removeEventListener("play", handlePlay);
      //     voiceoverRef.current.removeEventListener("pause", handlePause);
      //     voiceoverRef.current.removeEventListener("ended", handleEnded);
      //   }
      // };
    }
  }, []);

  useEffect(() => {
    if (voiceoverRef.current) {
      voiceoverRef.current.addEventListener("timeupdate", updateProgressBar);
    }

    return () => {
      if (voiceoverRef.current) {
        voiceoverRef.current.removeEventListener(
          "timeupdate",
          updateProgressBar
        );
      }
    };
  }, []);

  useEffect(() => {
    const videoContent = document.querySelector(`.${style["video-content"]}`);
    const dimmingOverlay = document.createElement("div");
    dimmingOverlay.className = style["dimming-overlay"];
    videoContent.appendChild(dimmingOverlay);

    return () => {
      videoContent.removeChild(dimmingOverlay);
    };
  }, []);

  function handleGoBack() {
    logEvent(analytics, `button_click_back_to_prompt`);
    const subtitleContainer = document.getElementById(
      style["subtitle-container"]
    );
    if (subtitleContainer) {
      subtitleContainer.innerText = "";
      subtitleContainer.style.display = "none";
    }
    cancelAnimationFrame(animationRef.current);
    cancelAnimationFrame(subtitleRef.current);
    setIsVideo(false);
    setIsVideoFinished(false);
    pauseSlideshow();
    setVideoOptions(() => ({
      currentIndex: null,
      finalPromptResponseHistory: [],
      isPlaying: false,
      intervalId: null,
      startTime: null,
      elapsedTime: null,
      segmentDurations: [],
      imgPaths: [],
      musicFileUrl: null,
      subtitles: [],
      subtitleIntervalId: null,
      voiceoverUrl: null,
      pausedTime: 0,
    }));
    // Reset isFirstPlay and isVideoContentVisible
    setIsFirstPlay(true);
    setIsVideoContentVisible(false);

    const dimmingOverlay = document.querySelector(
      `.${style["dimming-overlay"]}`
    );
    if (dimmingOverlay) {
      dimmingOverlay.style.opacity = "0";
      setTimeout(() => {
        dimmingOverlay.style.display = "none";
        document
          .querySelectorAll(".pricing-button, .header-button")
          .forEach((button) => {
            button.classList.remove("dimmed");
          });
      }, 300);
    }
  }

  function handlePlayClick() {
    logEvent(analytics, `button_click_play`);
    setIsVideoFinished(false);
    const imageContainer = document.getElementById(style["image-container"]);
    imageContainer.style.height = "75vh";
    imageContainer.style.objectFit = "contain";

    if (!videoOptions.isPlaying && voiceoverRef.current) {
      const imageContainer = imageContainerRef.current;

      if (pausedTime !== 0 && voiceoverRef.current) {
        voiceoverRef.current.currentTime = pausedTime;
        setPausedTime(0); // Clear paused time when resuming
      }

      if (isFirstPlay) {
        logEvent(analytics, `video_start_first`);
        imageContainer.classList.add(style["fade-in"]);
        imageContainer.addEventListener(
          "animationend",
          () => {
            imageContainer.classList.remove(style["fade-in"]);
          },
          { once: true }
        );
        setIsFirstPlay(false);
        // Set isVideoContentVisible to true here
        setIsVideoContentVisible(true);
      }

      if (isPaused) {
        const currentImage = imageContainerRef.current.querySelector(
          `.${style["panning-image"]}.${style["active"]}`
        );
        if (currentImage) {
          currentImage.classList.remove(style.paused);
          setIsPaused(false);
        }
      }
      voiceoverRef.current.currentTime = videoOptions.pausedTime;

      startSlideshow();

      if (voiceoverRef.current && backgroundMusicRef.current) {
        Promise.all([
          voiceoverRef.current
            .play()
            .catch((e) => console.error("Voiceover play error:", e)),
          backgroundMusicRef.current
            .play()
            .catch((e) => console.error("Background music play error:", e)),
        ]).catch((error) => console.error("Error playing audio:", error));
      }

      const dimmingOverlay = document.querySelector(
        `.${style["dimming-overlay"]}`
      );
      dimmingOverlay.classList.add("play");
      dimmingOverlay.classList.remove("pause");
      dimmingOverlay.style.display = "block";
      setTimeout(() => {
        dimmingOverlay.style.opacity = "1";
        document
          .querySelectorAll(".pricing-button, .header-button")
          .forEach((button) => {
            button.classList.add("dimmed");
          });
        document.querySelector(`.${style["back-button"]}`)?.classList.add(style.dimmed);
      }, 0);
    }
  }

  function handleContainerClick() {
    if (videoOptions.isPlaying) {
      requestAnimationFrame(pauseSlideshow);
    } else {
      handlePlayClick();
    }
  }

  function getNextIndex(currentIndex, totalImages) {
    if (currentIndex + 1 >= totalImages) {
      logEvent(analytics, `video_end`);
      setVideoOptions((prev) => ({
        ...prev,
        currentIndex: 0,
        startTime: null,
        elapsedTime: 0,
        isPlaying: false,
        intervalId: 0,
        elapsedTime: 0,
        subtitleIntervalId: null,
      }));
      cancelAnimationFrame(animationRef.current);
      cancelAnimationFrame(subtitleRef.current);
      const audioPlayer = voiceoverRef.current;
      audioPlayer.currentTime = 0;
      setPausedTime(0);
      setIsVideoFinished(true);
      const images = document.getElementsByClassName(style["panning-image"]);
      for (var i = 0; i < images.length; i++) {
        images[i].style.transform = `translateX(0%)`;
      }
      const subtitleContainer = document.getElementById(
        style["subtitle-container"]
      );
      if (subtitleContainer) {
        subtitleContainer.innerText = "";
        subtitleContainer.style.display = "none";
      }

      const imageContainer = document.getElementById(style["image-container"]);
      imageContainer.style.height = "50vh";

      const currentImage = imageContainerRef.current.querySelector(
        `.${style["panning-image"]}.${style["active"]}`
      );
      if (currentImage) {
        currentImage.style.objectFit = "cover";
      }
      pauseSlideshow();
    } else {
      return (currentIndex + 1) % totalImages;
    }
  }

  // const[localTime, setLoacalTime] = useState(0)
  const startTimeRef = useRef(null);

  function animateImage(timestamp) {
    if (videoOptions.isPlaying) {
      let startTime = startTimeRef.current;
      if (!startTime) {
        startTime = timestamp;
        startTimeRef.current = startTime;
        setVideoOptions((prev) => {
          return { ...prev, startTime: startTime };
        });
      }

      const elapsed = timestamp - startTime + videoOptions.elapsedTime;

      const duration =
        videoOptions.segmentDurations[videoOptions.currentIndex || 0] * 1000;

      const progress = Math.min(elapsed / duration, 1);

      const currentImage = imageContainerRef.current.querySelector(
        `.${style["panning-image"]}.${style["active"]}`
      );

      if (currentImage) {
        const translateX = -19.5 * progress;
        // const panTime = duration / 1000;
        currentImage.style.transform = `translateX(${translateX}%)`;
        // currentImage.style.animation = `pan ${panTime}s linear infinite`;
      }

      if (progress < 1) {
        animationRef.current = requestAnimationFrame(animateImage);
      } else {
        startTimeRef.current = null;
        goToNextImage();
      }
    }
  }

  function goToNextImage() {
    cancelAnimationFrame(subtitleRef.current);
    setVideoOptions((prev) => {
      const nextIndex = getNextIndex(prev.currentIndex, prev.imgPaths.length);
      return {
        ...prev,
        // isPlaying: true,
        elapsedTime: 0,
        currentIndex: nextIndex,
        startTime: null,
      };
    });

    // if (videoOptions.isPlaying) {
    //   animationRef.current = requestAnimationFrame(animateImage);
    // }
  }

  function startSlideshow() {
    const images = imageContainerRef.current.querySelectorAll(
      `.${style["panning-image"]}`
    );
    if (images.length > 0) {
      if (voiceoverRef.current) {
        voiceoverRef.current.addEventListener("timeupdate", updateProgressBar);
      }

      imageContainerRef.current.style.border =
        "1px solid rgba(255, 255, 255, 0.15)";
      imageContainerRef.current.style.boxShadow =
        "0 0 34px rgba(255, 255, 255, 0.35), 0 0 40px rgba(0, 123, 255, 0.3)";
      imageContainerRef.current.style.backdropFilter = "blur(10px)";
      imageContainerRef.current.style.background =
        "linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05))";
      document.getElementById(style["subtitle-container"]).style.display =
        "block";
      document.getElementById(style["subtitle-container"]).style.opacity = "1";
      setVideoOptions((prev) => ({
        ...prev,
        currentIndex: prev.currentIndex || 0,
        isPlaying: true,
        startTime: pausedTime ? performance.now() - pausedTime : null,
      }));

      startTimeRef.current = null;

      animationRef.current = requestAnimationFrame(animateImage);
      subtitleRef.current = requestAnimationFrame(updateSubtitle);
    }
  }

  function pauseSlideshow(timestamp) {
    logEvent(analytics, `button_click_pause`);
    if (videoOptions.isPlaying) {
      if (animationRef.current) {
        setIsPaused(true);
        setVideoOptions((prev) => ({
          ...prev,
          pausedTime: voiceoverRef.current?.currentTime || 0,
        }));
        cancelAnimationFrame(animationRef.current);
        cancelAnimationFrame(subtitleRef.current);
      }

      if (subtitleRef.current) {
        cancelAnimationFrame(subtitleRef.current);
      }

      const currentImage = imageContainerRef.current?.querySelector(
        `.${style["panning-image"]}.${style["active"]}`
      );
      if (currentImage) {
        currentImage.classList.add(style.paused);
      }

      setPausedTime(timestamp || 0);

      setVideoOptions((prev) => ({
        ...prev,
        startTime: timestamp,
        elapsedTime: timestamp - startTimeRef.current + prev.elapsedTime,
        isPlaying: false,
      }));

      if (voiceoverRef.current) {
        voiceoverRef.current.pause();
        voiceoverRef.current.removeEventListener(
          "timeupdate",
          updateProgressBar
        );
      }

      if (backgroundMusicRef.current) {
        backgroundMusicRef.current.pause();
      }

      const dimmingOverlay = document.querySelector(
        `.${style["dimming-overlay"]}`
      );
      if (dimmingOverlay) {
        dimmingOverlay.classList.add("pause");
        dimmingOverlay.classList.remove("play");
        dimmingOverlay.style.opacity = "0";
        setTimeout(() => {
          dimmingOverlay.style.display = "none";
          document
            .querySelectorAll(".pricing-button, .header-button")
            .forEach((button) => {
              button.classList.remove("dimmed");
            });
          document.querySelector(`.${style["back-button"]}`)?.classList.remove(style.dimmed);
        }, 350);
      }
    } else {
      setIsPaused(true);
      const currentImage = imageContainerRef.current?.querySelector(
        `.${style["panning-image"]}.${style["active"]}`
      );
      if (currentImage) {
        currentImage.classList.add(style.paused);
      }
      cancelAnimationFrame(animationRef.current);
      cancelAnimationFrame(subtitleRef.current);
      setPausedTime(timestamp || 0);
    }
  }

  function updateSubtitle() {
    if (videoOptions.isPlaying) {
      const currentTime = voiceoverRef.current?.currentTime;
      const subtitleIndex = videoOptions.subtitles.findIndex(
        (s) => currentTime >= s.start && currentTime < s.end
      );
      const subtitleContainer = document.getElementById(
        style["subtitle-container"]
      );

      if (subtitleContainer) {
        if (subtitleIndex !== -1) {
          const subtitle = videoOptions.subtitles[subtitleIndex];
          subtitleContainer.innerText = subtitle.text;
          subtitleContainer.style.display = "block";
          subtitleContainer.style.opacity = "1";
        } else {
          // Instead of clearing the text, we'll keep the last subtitle
          if (subtitleContainer.innerText === "") {
            subtitleContainer.style.display = "none";
          } else {
            subtitleContainer.style.display = "block";
            subtitleContainer.style.opacity = "1";
          }
        }
      }

      subtitleRef.current = requestAnimationFrame(updateSubtitle);
    }
  }

  function updateProgressBar() {
    const audio = voiceoverRef.current;
    const progress = (audio?.currentTime / audio?.duration) * 100;
    const progressBar = document.getElementById(style["progress-bar"]);
    if (progressBar) {
      progressBar.style.width = progress + "%";
    }
  }

  const loadingSteps = [
    { message: "Creating plan", minDuration: 5500, maxDuration: 7000 },
    { message: "Generating narration", minDuration: 6500, maxDuration: 8500 },
    { message: "Crafting visuals", minDuration: 6500, maxDuration: 8500 },
    { message: "Fetching Music", minDuration: 6500, maxDuration: 8500 },
    { message: "Composing", minDuration: null, maxDuration: null } // Final state
  ];

  const [currentLoadingMessage, setCurrentLoadingMessage] = useState(
    loadingSteps[0].message
  );

  useEffect(() => {
    if (!isLoading) return;

    const timeouts = [];
    let currentTime = 0;

    // Schedule all messages except the last one
    loadingSteps.slice(0, -1).forEach((step, index) => {
      const duration = Math.floor(
        Math.random() * (step.maxDuration - step.minDuration + 1) + step.minDuration
      );
      
      const timeout = setTimeout(() => {
        setCurrentLoadingMessage(loadingSteps[index + 1].message);
      }, currentTime + duration);
      
      timeouts.push(timeout);
      currentTime += duration;
    });

    // Cleanup function
    return () => {
      timeouts.forEach(timeout => clearTimeout(timeout));
    };
  }, [isLoading]);

  return (
    <div className={style["video-content"]}>
      {isLoading && (
        <div className={`${style["loader-container"]} ${isLoading ? style.loading : ""}`}>
          <NeuralLoader message={currentLoadingMessage} />
        </div>
      )}

      <>
        <div
          id={style["image-container"]}
          ref={imageContainerRef}
          onClick={handleContainerClick}
          style={{ display: isLoading ? "none" : "block" }}
        >
          {/* Display watermark only when video content is visible */}
          {isVideoContentVisible && (
            <div className={style["watermark"]}>
              <img src={watermarkImage} alt="Site watermark" />
            </div>
          )}
          <div className={style["glossy-overlay"]}></div>
          <div id={style["progress-bar"]}></div>
          <div
            id={style["controls-container-inner"]}
            style={{ display: isFirstPlay ? "block" : "" }}
          >
            {videoOptions.isPlaying && !isLoading ? (
              <button
                id={style["pause-button"]}
                className={style["icon-button"]}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
                </svg>
              </button>
            ) : (
              <button
                id={style["play-button"]}
                className={style["icon-button"]}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M8 5v14l11-7z" />
                </svg>
              </button>
            )}
          </div>
          {videoOptions.imgPaths.map((path, index) => (
            <img
              key={index}
              src={path}
              alt={`Video frame ${index + 1}`}
              className={`${style["panning-image"]} ${
                videoOptions.currentIndex === index ? style["active"] : ""
              }`}
              style={{ transform: "translateX(0%)" }}
            />
          ))}
        </div>
      </>

      <div id={style["subtitle-container"]} style={{ display: "none" }}></div>
      <audio
        id={style["voiceover-audio"]}
        ref={voiceoverRef}
        src={videoOptions.voiceoverUrl}
        preload="auto"
      ></audio>
      <audio
        id={style["background-music"]}
        ref={backgroundMusicRef}
        src={videoOptions.musicFileUrl}
        preload="auto"
        loop
      ></audio>

      {/* <div
        id={style["new-continue-buttons"]}
        style={{ display: "none", marginTop: "20px" }}
      >
        <button id={style["continue-button"]}>Continue</button>
        <button id={style["new-button"]}>New</button>
        <button id={style["download-button"]}>Export</button>
      </div> */}

      <div
        className={style["continue-container"]}
        style={{ display: isLoading ? "none" : "block" }}
      >
        {isVideoFinished && (
          <div className={style["continue-input-container"]}>
            <input
              type="text"
              className={style["continue-input"]}
              placeholder="(Optional) Enter a follow-up request/question"
              onChange={(e) => setStoryPrompt(e.target.value)}
              value={storyPrompt}
            />
            <button
              className={style["submit-continue"]}
              onClick={() => {
                handleSubmit(
                  "continuation",
                  storyPrompt,
                  dropDownOptions,
                  setAlertOptions,
                  setIdeaInputText,
                  setUserData
                );
                setIsVideoFinished(false);
                const imageContainer = document.getElementById(
                  style["image-container"]
                );
                imageContainer.style.height = "75vh";
                imageContainer.style.objectFit = "cover";
              }}
            >
              &rsaquo;
            </button>
          </div>
        )}
        {/* Temporarily hidden suggestion pills
        {isVideoFinished && (
          <div className={style["continue-suggestions-container"]}>
            {nextVideoSuggestions.map((suggestion, index) => (
              <div
                className={style["suggestion-chip"]}
                key={index}
                onClick={() => setStoryPrompt(suggestion)}
                // ADD ANALYTICS
              >
                {suggestion}
              </div>
            ))}
          </div>
        )}
        */}
        <div
          className={style["controls-container"]}
          style={{ display: isLoading ? "none" : "flex" }}
        >
          <button className={style["back-button"]} onClick={handleGoBack}>
            <ChevronLeft height="18px" width="18px" color="rgb(126, 79, 184)" />
            Back to Feature
          </button>
        </div>
      </div>
    </div>
  );
};
