import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { gsap } from 'gsap';
import { fragmentShader, vertexShader } from './shaders';
import './Globe.css';

const Globe = () => {
  const containerRef = useRef(null);
  const canvas3DRef = useRef(null);
  const canvas2DRef = useRef(null);
  const popupRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const controlsRef = useRef(null);
  const pointsRef = useRef(null);
  const materialRef = useRef(null);
  const timeRef = useRef(0);
  const lastClickRef = useRef({ time: 0, position: new THREE.Vector3() });

  useEffect(() => {
    if (!containerRef.current) return;

    const init = async () => {
      if (!containerRef.current || !canvas3DRef.current) {
        return;
      }
      // Scene setup
      sceneRef.current = new THREE.Scene();
      
      // Camera setup
      const fov = 60;
      const aspect = containerRef.current.clientWidth / containerRef.current.clientHeight;
      cameraRef.current = new THREE.PerspectiveCamera(fov, aspect, 0.1, 100);
      cameraRef.current.position.z = 2.5;

      // Renderer setup
      rendererRef.current = new THREE.WebGLRenderer({
        canvas: canvas3DRef.current,
        alpha: true,
        antialias: true
      });
      rendererRef.current.setSize(containerRef.current.clientWidth, containerRef.current.clientHeight);
      rendererRef.current.setPixelRatio(Math.min(window.devicePixelRatio, 2));

      // Controls setup
      controlsRef.current = new OrbitControls(cameraRef.current, canvas3DRef.current);
      controlsRef.current.enabled = false; // Disable all mouse/touch interactions
      controlsRef.current.autoRotate = true;
      controlsRef.current.autoRotateSpeed = 0.5;

      // Load texture
      const textureLoader = new THREE.TextureLoader();
      const mapTexture = await new Promise((resolve) => {
        textureLoader.load('https://ksenia-k.com/img/earth-map-colored.png', resolve);
      });

      // Create points geometry
      const geometry = new THREE.IcosahedronGeometry(1, 20);
      
      // Create shader material
      materialRef.current = new THREE.ShaderMaterial({
        uniforms: {
          u_map_tex: { value: mapTexture },
          u_dot_size: { value: 5 },
          u_time_since_click: { value: 0.0 },
          u_pointer: { value: new THREE.Vector3() }
        },
        vertexShader,
        fragmentShader,
        transparent: true,
        depthWrite: false
      });

      // Create points mesh
      pointsRef.current = new THREE.Points(geometry, materialRef.current);
      sceneRef.current.add(pointsRef.current);

      // Animation loop
      const animate = () => {
        requestAnimationFrame(animate);
        
        if (controlsRef.current) {
          controlsRef.current.update();
        }

        timeRef.current += 0.01;
        if (materialRef.current) {
          materialRef.current.uniforms.u_time_since_click.value = 
            timeRef.current - lastClickRef.current.time;
        }

        if (rendererRef.current && sceneRef.current && cameraRef.current) {
          rendererRef.current.render(sceneRef.current, cameraRef.current);
        }
      };

      animate();

      // Handle window resize
      const handleResize = () => {
        if (!containerRef.current) return;
        
        const width = containerRef.current.clientWidth;
        const height = containerRef.current.clientHeight;

        if (cameraRef.current) {
          cameraRef.current.aspect = width / height;
          cameraRef.current.updateProjectionMatrix();
        }

        if (rendererRef.current) {
          rendererRef.current.setSize(width, height);
        }
      };

      window.addEventListener('resize', handleResize);

      // Cleanup
      return () => {
        window.removeEventListener('resize', handleResize);
        
        if (geometry) geometry.dispose();
        if (materialRef.current) materialRef.current.dispose();
        if (mapTexture) mapTexture.dispose();
        if (rendererRef.current) rendererRef.current.dispose();
        if (controlsRef.current) controlsRef.current.dispose();
      };
    };

    init();
  }, []);

  return (
    <div ref={containerRef} className="globe-container">
      <canvas ref={canvas3DRef} className="globe-canvas" />
      <canvas ref={canvas2DRef} className="globe-overlay" />
      <div ref={popupRef} className="globe-popup" />
    </div>
  );
};

export default Globe;
