import React, { useContext, useEffect, useRef, useState } from "react";
import style from "./generative-search.module.css";
import {
  getRandomInitialSuggestions,
  handleGenerateClick,
} from "./generative-search-util-function";
import { PlaceholdersAndVanishInput } from "./GenerativeSearchComponents/GenerativeSearchInput/placeholders-and-vanish-input";
import { GenerativeSearchSuggestions } from "./GenerativeSearchComponents/GenerativeSearchSuggestions/GenerativeSearchSuggestions";
import { GenerativeSearchIdeas } from "./GenerativeSearchComponents/GenerativeSearchCards/GenerativeSearchCards";
import { VideoPlayer } from "../../components/VideoPlayer/VideoPlayer";
import { AppContext } from "../../context/appContext";
import { VideoContext } from "../../context/videoContext";
import { Alert } from "../../components/Alert/Alert";
import axios from "axios";
import { Helmet } from "react-helmet-async";
// Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

export const GenerativeSearch = () => {
  const { setAlertOptions, setUserData } = useContext(AppContext);
  const { handleSubmit, isVideo, setIdeaInputText } = useContext(VideoContext);
  const analytics = getAnalytics();

  const [searchInput, setSearchInput] = useState("");
  const [ideas, setIdeas] = useState(Array(6).fill({ ready: false }));
  const [initialSuggestions, setInitialSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCards, setShowCards] = useState(false);
  const [loadedImages, setLoadedImages] = useState({});
  const [fadeInIndices, setFadeInIndices] = useState([]);
  const [hasStartedLoading, setHasStartedLoading] = useState(false);
  const canvasRef = useRef(null);
  const [alert, setAlert] = useState({ show: false, status: "", text: "" });

  useEffect(() => {
    generateNewSuggestions();
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      ctx.lineWidth = 10;

      function drawInfinity(t) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const gradient = ctx.createLinearGradient(0, 0, 430, 430);
        gradient.addColorStop(0, `hsl(${(t * 50) % 360}, 70%, 60%)`);
        gradient.addColorStop(0.33, `hsl(${(t * 50 + 60) % 360}, 70%, 60%)`);
        gradient.addColorStop(0.66, `hsl(${(t * 50 + 120) % 360}, 70%, 60%)`);
        gradient.addColorStop(1, `hsl(${(t * 50 + 180) % 360}, 70%, 60%)`);

        ctx.strokeStyle = gradient;
        ctx.beginPath();
        ctx.moveTo(215, 215);
        ctx.bezierCurveTo(430, 0, 430, 430, 215, 215);
        ctx.bezierCurveTo(0, 0, 0, 430, 215, 215);
        ctx.closePath();
        ctx.stroke();
      }

      let animationId;
      function animate(time) {
        drawInfinity(time / 1000);
        animationId = requestAnimationFrame(animate);
      }
      animate(0);

      return () => {
        cancelAnimationFrame(animationId);
      };
    }
  }, []);

  const generateNewSuggestions = (theme = "") => {
    const newSuggestions = getRandomInitialSuggestions(theme).slice(0, 3); // Limit to 3 suggestions
    setInitialSuggestions(newSuggestions);
    setFadeInIndices([]);
    newSuggestions.forEach((_, index) => {
      setTimeout(() => {
        setFadeInIndices((prev) => [...prev, index]);
      }, 100 * index);
    });
  };

  // const handleSuggestionClick = (suggestion) => {
  //   setSearchInput(suggestion);
  //   generateNewSuggestions(suggestion);
  // ADD ANALYTICS
  // };

  const handleGenerate = async (e) => {
    console.log("handleGenerate", searchInput);
    logEvent(analytics, `button_click_generative_search_generate`, {
      searchInput: searchInput,
    });
    e.preventDefault();
    if (searchInput.trim() !== "") {
      try {
        // Immediately show loading states
        setIsLoading(true);
        setHasStartedLoading(true);
        setShowCards(true);
        setIdeas(ideas.map(() => ({ ready: false })));

        // Perform NSFW check
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/check_nsfw`,
          {
            prompt: searchInput,
          }
        );

        console.log(response.data);

        if (response.data.isNSFW) {
          // Revert loading states
          console.log("NSFW content detected - setting isLoading to false");
          logEvent(analytics, `generative_search_nsfw_detected`);
          setIsLoading(false);
          setShowCards(false);
          setHasStartedLoading(false);

          setAlertOptions({
            show: true,
            status: "warning",
            text: "Error - nudity detected.",
            autoClose: true,
          });
          setAlert({
            show: true,
            status: "warning",
            text: "Error - nudity detected.",
            autoClose: true,
          });
          return;
        }

        // If not NSFW, continue with generation
        handleGenerateClick(
          searchInput,
          isLoading,
          setIsLoading,
          setShowCards,
          setIdeas,
          ideas
        );
      } catch (error) {
        // Revert loading states
        setIsLoading(false);
        setShowCards(false);
        setHasStartedLoading(false);

        console.error("Error during generation:", error);
        setAlertOptions({
          show: true,
          status: "warning",
          text: "An error occurred during generation",
        });
      }
    }
  };

  const handleCardGenerate = (title, description) => {
    handleSubmit(
      "generative search",
      `"${title}" ${description}`,
      {
        style: "Digital",
        mode: "Torsera",
        length: "Auto",
        voice: "Male",
      },
      setAlertOptions,
      setIdeaInputText,
      setUserData
    );
  };

  const handleRecentRequestClick = (request) => {
    setSearchInput(request);
    logEvent(analytics, `button_click_generative_search_recent_request`);
  };

  const handleAlertClose = () => {
    setAlert({ show: false, status: "", text: "" });
    setAlertOptions({ show: false, status: "", text: "" });
  };

  return (
    <>
      <Helmet>
        <title>
          Torsera Generative Search - Generate Endless Topics and Ideas
        </title>
        <meta
          name="description"
          content="Generate endless topics and ideas using AI"
        />
      </Helmet>
      <div className={`${style["generative-search"]}`}>
        {alert.show && (
          <Alert
            status={alert.status}
            text={alert.text}
            onClose={handleAlertClose}
            autoClose={alert.autoClose}
          />
        )}
        {!isVideo ? (
          <>
            <div className={style["trailing-dots-background"]}></div>
            <div
              className={`${style["content-wrapper"]} flex flex-col items-center w-full`}
            >
              <div
                className={`${style["input-container"]} w-full max-w-2xl mb-12`}
              >
                <PlaceholdersAndVanishInput
                  placeholders={[
                    "Search anything you want",
                    "Explore new ideas",
                    "Discover the unknown",
                    "Push some boundaries",
                  ]}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onSubmit={handleGenerate}
                  value={searchInput}
                  isLoading={isLoading}
                />
              </div>
              <div className="relative w-full flex flex-col items-center">
                {/* Temporarily commented out suggestion pills
              <GenerativeSearchSuggestions
                initialSuggestions={initialSuggestions}
                setSearchInput={handleSuggestionClick}
                fadeInIndices={fadeInIndices}
              />
              */}
                {!showCards && (
                  <canvas
                    ref={canvasRef}
                    className={`${style["infinite-symbol"]} ${style["infinite-symbol-gradient"]}`}
                    width="533.5"
                    height="533.5"
                  />
                )}
              </div>
              {showCards && (
                <div
                  className={`${style["cards-background-container"]} ${style["fade-in"]} w-full max-w-6xl px-2 sm:px-4 md:px-6 lg:px-8`}
                >
                  <GenerativeSearchIdeas
                    ideas={ideas.map((idea) => ({
                      ...idea,
                      ready: idea.imageUrl !== undefined,
                    }))}
                    loadedImages={loadedImages}
                    setLoadedImages={setLoadedImages}
                    isLoading={isLoading}
                    onGenerate={handleCardGenerate}
                  />
                </div>
              )}
            </div>
            <div className={style["gradient-orb"]}></div>
            <div className={style["uuundulate-background"]}></div>
            {!hasStartedLoading && (
              <div className={style["recent-requests-container"]}>
                <h3 className={style["recent-requests-title"]}>
                  Recent user requests
                </h3>
                <div className={style["recent-requests-grid"]}>
                  <div
                    className={`${style["request-item"]} ${style["request-item-short"]}`}
                    onClick={() =>
                      handleRecentRequestClick("Space travel, nukes, aliens")
                    }
                  >
                    Space travel, nukes, aliens
                  </div>
                  <div
                    className={`${style["request-item"]} ${style["request-item-short"]}`}
                    onClick={() =>
                      handleRecentRequestClick("Easiest countries to invade")
                    }
                  >
                    Easiest countries to invade
                  </div>
                  <div
                    className={`${style["request-item"]} ${style["request-item-short"]}`}
                    onClick={() =>
                      handleRecentRequestClick("Alien civilizations")
                    }
                  >
                    Alien civilizations
                  </div>
                  <div
                    className={`${style["request-item"]} ${style["request-item-long"]}`}
                    onClick={() =>
                      handleRecentRequestClick(
                        "How to start a business in China?"
                      )
                    }
                  >
                    How to start a business in China?
                  </div>
                  <div
                    className={`${style["request-item"]} ${style["request-item-long"]}`}
                    onClick={() =>
                      handleRecentRequestClick(
                        "What are the most wild crimes in history?"
                      )
                    }
                  >
                    What are the most wild crimes in history?
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className={style.videoContainer}>
            <VideoPlayer
              dropDownOptions={{
                style: "Digital",
                mode: "Torsera",
                length: "Auto",
                voice: "Male",
              }}
              setIdeaInputText={setIdeaInputText}
            />
          </div>
        )}
      </div>
    </>
  );
};
