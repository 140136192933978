import React from "react";
import siteNamePlusIcon from "../../images/sitenameplusicon.svg";

const Header = ({ handleLoginClick }) => (
  <header className="flex items-center justify-between py-8">
    <div className="flex items-center">
      <img
        src={siteNamePlusIcon}
        alt="Torsera"
        className="h-8"
      />
    </div>
    <button
      className="bg-purple-800/70 hover:bg-purple-700/90 text-zinc-100 font-semibold py-2 px-6 rounded-full transition duration-300 border border-purple-600/90 shadow-lg hover:shadow-purple-500/50 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-offset-2 focus:ring-offset-zinc-950 backdrop-blur-sm"
      onClick={handleLoginClick}
    >
      Login
    </button>
  </header>
);

export default Header;
